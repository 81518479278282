<template>
  <div class="container-fluid">
    <PageTitle noAdd />

    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"
        :no-btn-mass="true"
        :no-multi-select="true"
      >
      <template #aksi="data">
            <b-button
              v-if="!moduleRole('ViewOnly')"
              :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}"
              class="btn btn-secondary"
            >
              <i class="icon-pencil"></i>
            </b-button>
            <span v-else>-</span>
          </template>
      </card-list>
    </template>
     <div 
        v-else 
        class="card"
      >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd ? "Tambah": "Ubah"}} {{modulePage}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
             <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Deskripsi
                  </template>
                  <b-input 
                    type="text"
                    v-model="row.ac_name"
                    placeholder="Deskripsi"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Nilai <span class="text-danger mr5">*</span>
                  </template>
                  <b-input-group append="MB">
                    <b-form-input v-model="row.ac_value"></b-form-input>
                  </b-input-group>
                  <VValidate 
                    name="Nilai" 
                    v-model="row.ac_value" 
                    :rules="mrValidation[row.ac_slug]" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'

export default {
   extends:GlobalVue
   ,
   components: {
      PageTitle,
      CardList
   },
   data(){
     return {
       idKey:'ac_id',
      fields:[
        { key:'number', label:'#' },
        { key: 'ac_name', label: 'Deskripsi' },
        { key: 'ac_value', label: 'Nilai' },
        { key: 'aksi', label: 'Aksi', is_custom: true, thClass: 'w_170' }
      ],
     }
   },
   mounted() {
     this.apiGet();
   },
   watch:{
    $route(){
      this.apiGet()
    }
  },
}
</script>